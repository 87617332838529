import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./components/pages/home-four/home.module').then(m => m.HomeModule), data: { breadcrumb: "Pagina Inicial" } },
  { path: 'empresa', loadChildren: () => import('./components/pages/about/about.module').then(m => m.AboutModule), data: { breadcrumb: "Quem somos" } },
  // CATALOGO COMENTADO ATE OBTER MASSA DE DADOS
  // { path: 'produtos', loadChildren: () => import('./components/pages/services/services.module').then(m => m.ServicesModule), data: { breadcrumb: "Catálogo de Produtos" } },
  // { path: 'detalhamento/:id', loadChildren: () => import('./components/pages/service-details/service-details.module').then(m => m.ServiceDetailsModule), data: { breadcrumb: "Detalhes dos Produtos" } },
  { path: 'equipe', loadChildren: () => import('./components/pages/team/team.module').then(m => m.TeamModule), data: { breadcrumb: "Equipe" } },
  { path: 'linha-do-tempo', loadChildren: () => import('./components/pages/company/company.module').then(m => m.CompanyModule), data: { breadcrumb: "História da Portal" } },
  { path: 'parceiros', loadChildren: () => import('./components/pages/struture-proccess/struture-proccess.module').then(m => m.StrutureProccessModule), data: { breadcrumb: "Nossos Parceiros" } },
  { path: 'error-404', loadChildren: () => import('./components/pages/error-page/error-page.module').then(m => m.ErrorPageModule), data: { breadcrumb: "Error 404" } },
  { path: 'contato', loadChildren: () => import('./components/pages/contact/contact.module').then(m => m.ContactModule), data: { breadcrumb: "Contato" } },
  { path: 'home-v4', loadChildren: () => import('./components/pages/home-four/home.module').then(m => m.HomeModule), data: { breadcrumb: "Pagina Inicial" } },
  { path: '**', loadChildren: () => import('./components/pages/home-four/home.module').then(m => m.HomeModule), data: { breadcrumb: "Pagina Inicial" } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
